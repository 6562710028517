@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.chat-scroll::-webkit-scrollbar, .message ::-webkit-scrollbar, #chat-input::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: rgba(255,255,255,.05);
}

.chat-scroll::-webkit-scrollbar-thumb, .message ::-webkit-scrollbar {
  --tw-border-opacity: 1;
  background-color: rgba(217,217,227,.8);
  border-color: rgba(255,255,255,var(--tw-border-opacity));
  border-radius: 9999px;
  border-width: 1px;
}

.chat-scroll input{
  caret-color: white;
  /* create box shadow */
  --tw-shadow: 0 0 15px rgba(0,0,0,.3);
  --tw-shadow-colored: 0 0 15px var(--tw-shadow-color);
  --tw-ring-offset-shadow: 0 0 transparent;
  --tw-ring-shadow: 0 0 transparent;
  box-shadow: var(--tw-ring-offset-shadow,0 0 transparent),var(--tw-ring-shadow,0 0 transparent),var(--tw-shadow);
}

.message p{
  margin-bottom: 20px;
}

.bg-sidebar {
  --tw-bg-opacity: 1;
  background-color: rgba(32,33,35,var(--tw-bg-opacity));
}